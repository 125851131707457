
import { Vue, Component, Watch } from "vue-property-decorator";
import dayjs from "@/plugins/day-js";
import Page from "@/components/core/dashboard/Page.vue";
import { DataOptions, DataTableHeader } from "vuetify";

import Modal from "@/components/Modal.vue";
import SafetyService from "../services/safety-service";
import { ProductsTypeEnum } from "@/types/ProductsTypeEnum";
import { BankingEnum } from "@/types/BankingEnum";
import { AdministratorEnum } from "@/types/AdministratorEnum";
import { FundsEnum } from "@/types/FundsEnum";
import { format as formatCNPJ } from "@/utils/cnpj";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import FinancialCompanyService from "@/services/financial-company-service";
import GlobalParams from "@/types/globalParams";
import { ValidationObserver, ValidationProvider } from "vee-validate";

@Component({
  components: {
    Modal,
    Page,
    ValidationObserver,
    ValidationProvider
  }
})
export default class GlobalParameterManagement extends Vue {
  options: DataOptions;
  loading = true;
  service: FinancialCompanyService;
  safetyService: SafetyService;
  formatCNPJ = formatCNPJ;
  loadingExcel: boolean;
  loadingGlobalParams = true;
  globalParamsList: Array<GlobalParams>;
  originalGlobalParamsList: Array<GlobalParams>;
  selectedSegment: number; // Default to PRIVADO (1)
  statusOptions = [
    { text: "Ativo", value: true },
    { text: "Inativo", value: false }
  ];
  headersOfGlobalParams: Array<DataTableHeader> = [
    { text: "Produto", value: "typeId", sortable: false },
    { text: "Status", value: "isActive", sortable: false },
    {
      text: "Bancarizadora",
      value: "bankingId",
      sortable: false
    },
    {
      text: "Administradora",
      value: "adminId",
      sortable: false
    },
    { text: "Fundo", value: "fundId", sortable: false },
    { text: "% TAC", value: "tacPercentage", sortable: false },
    { text: "Limite TAC", value: "tacLimit", sortable: false },
    {
      text: "Dias de carência",
      value: "gracePeriod",
      sortable: false
    },
    {
      text: "Limite por contrato",
      value: "requestedAmountLimit",
      sortable: false
    },
    { text: "% Ágio", value: "agioPercent", sortable: false },
    {
      text: "Tarifa do Banco",
      value: "bankFee",
      sortable: false
    },
    {
      text: "Máx. Margem",
      value: "maxMarginPercentage",
      sortable: false
    }
  ];
  isEditMode: boolean;
  products = Object.values(ProductsTypeEnum).map((product) => ({
    name: product.description,
    id: product.id
  }));
  bankings = Object.values(BankingEnum).map((banking) => ({
    name: banking.description,
    id: banking.id
  }));

  administrators = Object.values(AdministratorEnum).map((admin) => ({
    name: admin.description,
    id: admin.id
  }));
  funds = Object.values(FundsEnum).map((fund) => ({
    name: fund.name,
    cnpj: fund.cnpj,
    id: fund.id
  }));
  headers: Array<DataTableHeader>;

  constructor() {
    super();
    this.service = FinancialCompanyService.getInstance();
    this.safetyService = SafetyService.getInstance();
    this.loadingExcel = false;
    this.isEditMode = false;
    this.selectedSegment = 1;
    this.options = {} as DataOptions;
  }

  created(): void {
    this.fetchGlobalParamsBySegment(this.selectedSegment);
  }

  @Watch("selectedSegment")
  onSegmentChange(newSegment: number) {
    this.fetchGlobalParamsBySegment(newSegment);
  }

  @Watch("isEditMode")
  onIsEditMode(newVal) {
    if (newVal) {
      this.originalGlobalParamsList = JSON.parse(
        JSON.stringify(this.globalParamsList)
      );
    } else {
      this.globalParamsList = JSON.parse(
        JSON.stringify(this.originalGlobalParamsList)
      );
    }
  }

  getProductTypeName(productTypeId) {
    const product = this.products.find((p) => p.id === productTypeId);
    return product.name;
  }

  getProductStatusName(statusId: boolean): string {
    return this.statusOptions.find((option) => option.value === statusId)?.text;
  }

  getBankingName(bankingId) {
    const banking = this.bankings.find((b) => b.id === bankingId);
    return banking.name;
  }

  getAdminstratorsName(adminId) {
    const admin = this.administrators.find((a) => a.id === adminId);
    return admin.name;
  }

  getFundName(fundId) {
    const fund = this.funds.find((f) => f.id === fundId);
    return `${fund.name} - ${formatCNPJ(fund.cnpj)}`;
  }

  formatDate(date: string) {
    return dayjs(date).format("DD/MM/YYYY");
  }

  get isAdminGooroo() {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "ADMIN_GOOROO"
    );
  }

  async fetchGlobalParamsBySegment(segmentTypeId: number): Promise<void> {
    this.loadingGlobalParams = true;
    const [error, segmentData] = await this.service.getGlobalParamsBySegment(
      segmentTypeId
    );
    if (!error) {
      this.globalParamsList = segmentData;
      this.originalGlobalParamsList = segmentData;
      this.loadingGlobalParams = false;
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
  }

  async saveChanges() {
    const [error] = await this.service.updateGlobalParamsBySegment({
      segmentTypeId: this.selectedSegment,
      globalParamsList: this.globalParamsList
    });
    if (!error) {
      this.$notify({
        type: "success",
        text: "Parametros Globais Atualizados com sucesso."
      });
      this.fetchGlobalParamsBySegment(this.selectedSegment);
      this.isEditMode = false;
    } else {
      this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    }
  }
}
